@import './variables.scss';

.sound-volume {
  position: relative;
  margin-right: 5px;

  &:hover,
  &:focus {
    .video-player-sound-volume__container-absolute {
      .body--not-touch-device & {
        display: block;
      }
    }
  }

  &:focus {
    outline: unset;

    .sound-volume__volume-btn {
      outline:$outline-blue solid 2px;
    }
  }

  &__volume-btn {
    height: 44px;
    width: 38px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background: none;
    border-radius: 5px;
    align-items: center;
    border: unset;
    cursor: pointer;

    img {
      opacity: 1;
      transition: ease-out 0.2s;
      height: 26px;
    }
  }

  &__container-absolute {
    display: none;
    margin: 0 0 18px 5px;
    position: absolute;
    bottom: 26px;
    z-index: 2;

    &:hover {
      display: block;
    }

    &:focus {
      outline: none;
    }

    &.is-focus {
      display: flex;
      outline:$outline-blue solid 2px;
    }
  }

  &__show {
    display: block;
  }

  &__general-volume {
    display: flex;
    height: 128px;
    width: 28px;
    justify-content: center;
    flex-direction: row;
    border-radius: 4px;
    background:$black-opacity-80;
    align-items: center;
    border: unset;
    cursor: pointer;
  }

  &__input-container {
    overflow: hidden;
    height: 88px;
    width: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      transform: rotate(-90deg);
      appearance: none;
      width: 88px;
      height: 5px;
      border-bottom: unset;
      padding: unset;
      background-color:$gray-9;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-runnable-track {
        height: 5px;
        appearance: none;
        color:$blue;
        margin-top: -1px;
      }

      &::-webkit-slider-thumb {
        width: 0;
        appearance: none;
        height: 5px;
        border: unset;
        background:$white;
        box-shadow: -50px 12px 0 50px$white;
      }

      &::-moz-range-progress {
        background-color:$white;
        height: 5px;
      }

      &::-moz-range-track {
        background-color:$gray-5;
      }

      &::-moz-range-thumb {
        width: 0;
        appearance: none;
        height: 5px;
        border: unset;
        background:$white;
        box-shadow: -50px 12px 0 50px$white;
      }

      &::-ms-fill-lower {
        background-color:$white;
        height: 5px;
      }

      &::-ms-fill-upper {
        background-color:$gray-9;
      }

      &::-ms-thumb {
        width: 0;
        appearance: none;
        height: 5px;
        border: unset;
        background:$white;
        box-shadow: -50px 12px 0 50px$white;
      }
    }
  }
}

