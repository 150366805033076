.jw-player-container {
  :global {
    .jwplayer {
      &.jw-flag-user-inactive {
        &.jw-state-playing {
          > .jw-wrapper > .jw-controls > .jw-controlbar {
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
            transition-delay: 0s, 250ms;
          }
        }
      }
    }
  }
}
