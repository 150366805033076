// TODO Ask edison how to use tailwind colors in SCSS files
// Possible answer. Create figma import tool to generate tailwind + SCSS constants to make sure they're always aligned

$true-black: #000;
$black: #1e1e28;
$white: #fff;

$gray-1: #edf3f3;
$gray-2: #dee4e5;
$gray-3: #ced4d6;
$gray-4: #bfc5c8;
$gray-5: #b0b5ba; // Lighter copy for dark mode ("relax")
$gray-6: #a1a6ac;
$gray-7: #91969d;
$gray-8: #82878f; // Darker copy for dark mode ("relax")
$gray-9: #6c747d; // Introduced for accessibility purposes

$warm-black-light: #383539; // Warmer black (lighter) for dark mode ("relax")
$warm-black-dark: #222122; // Warmer black (darker) for dark mode ("relax")

$violet: #d9d6ff;
$green: #55c791;
$green-dark: darken($green, 10%);
$green-darker: rgba(0,43,63,0.87);
$sand: #e9dac7;
$sand-light: lighten($sand, 11%);
$yellow: #ffe146;
$blue: #0055dc;
$teal: #00bab6;
$cobalt: #b9d2dc;
$zen-blue: #badbd9;
$peach: #f3d7d7;
$red: #e86046;
$red-dark: #c0443a;
$red-darker: darken($red-dark, 10%);
$light-green: rgba(85,199,145,0.2);

$off-white: #faf9f7;
$off-white-alt: #f3f4f5;
$warm-gray: #eeecea;

$audio-blue: #b9d2dc;
$outline-blue: #5b90f6;

$black-opacity-20: rgba(0, 0, 0, 0.2);
$black-opacity-30: rgba(0, 0, 0, 0.3);
$black-opacity-50: rgba(0, 0, 0, 0.5);
$black-opacity-70: rgba(0, 0, 0, 0.7);
$black-opacity-80: rgba(0, 0, 0, 0.8);

// Partnerships
$strava-orange: #fc4c01;
$facebook-blue: #4367b2;

$promo-gradient: linear-gradient(342.14deg, $sand 4.63%, $cobalt 108.75%);
$transparent: transparent;
