.active-action-button-icon {
  path {
    fill: white;
    stroke: white;
  }
}

.inactive-action-button-icon {
  path {
    fill: white;
    stroke: var(--text-icon-tertiary);
  }
}

