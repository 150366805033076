@import './variables.scss';

.sound-mixer {
  display: block;

  &__mixer-btn {
    height: 44px;
    width: 38px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background: none;
    border-radius: 5px;
    align-items: center;
    border: unset;
    cursor: pointer;

    img {
      opacity: 1;
      transition: ease-out 0.2s;
      height: 24px;
    }
  }
}

.sound-mixer-slider {
  display: none;
  flex-direction: row;
  background:$black-opacity-80;
  padding: 14px;
  border-radius: 5px;
  align-items: center;
  color:$white;
  font-weight: 600;
  font-size: 10px;
  height: 30px;
  position: absolute;
  left: 24px;
  bottom: 44px;
  text-transform: uppercase;
  z-index: 10;
  transform: translateX(0);

  &--show {
    display: flex;
  }

  &:focus {
    outline: none;
  }

  &--is-focus {
    display: flex;
    outline:$outline-blue solid 2px;
  }
}

.video-mixer-input {
  appearance: none;
  margin: 0 9px;
  width: 150px;
  border-bottom: unset;
  padding: unset;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background:$gray-9;
  }

  &::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    border: unset;
    border-radius: 10px;
    background:$white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background:$gray-9;
    border-radius: 10px;
  }

  &::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border: unset;
    border-radius: 10px;
    background:$white;
    cursor: pointer;
    appearance: none;
    margin-top: -5px;
  }

  &::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background:$gray-9;
    border-radius: 10px;
  }

  &::-ms-thumb {
    height: 15px;
    width: 15px;
    border: unset;
    border-radius: 10px;
    background:$white;
    cursor: pointer;
    appearance: none;
    margin-top: -5px;
  }
}
